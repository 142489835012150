<template>
  <div class="max-w-2xl w-full mx-auto py-16 lg:py-24">
    <div class="flex flex-col justify-center items-center text-center">
      <h1 class="justify-center text-blue-800 font-bold text-2xl lg:text-4xl">
        Verification email address
      </h1>

      <div class="py-8 lg:py-12">
        <EmailIllustration />
      </div>

      <div class="text-defaultGray">
        <p>
          We sent you an email to <span class="font-semibold">{{ user.email }}</span> to verify your account
        </p>
        <p class="mt-2">
          Please check it out!
        </p>
      </div>

      <div class="mt-8 lg:mt-12 flex items-center justify-center flex-col text-defaultGray space-y-2">
        <p>
          Didn't get the email?
        </p>

        <button
          type="button"
          class="text-customPurple hover:underline disabled:opacity-75 disabled:cursor-not-allowed"
          :disabled="resending"
          @click="resendVerificationEmail"
        >
          {{ resending ? 'Resending...' : 'Resend' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { resendEmailVerificationNotification } from '@/api/auth';
  import EmailIllustration from '@/components/svgs/EmailIllustration';

  export default {
    name: 'CheckEmail',

    components: {
      EmailIllustration
    },

    data() {
      return {
        resending: false,
      };
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser'];
      },
    },

    created() {
      if (this.user.status !== 'pending') {
        this.$router.push({ name: 'ThankYou' });
      }
    },

    methods: {
      async resendVerificationEmail() {
        this.resending = true;

        try {
          await resendEmailVerificationNotification();

          this.$toast.success('The verification email was resent.');
        } catch (error) {
          this.$toast.error('Something went wrong. Try again or contact support.')
        } finally {
          this.resending = false;
        }
      }
    }
  }
</script>
